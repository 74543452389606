import { Content } from "@/components/common/layout/components";
import { Dashboard } from "@quillsql/react";
import { useRouter } from "next/router";

export default function Home() {
  const router = useRouter();
  const { query } = router;

  const queryString = new URLSearchParams(
    query as Record<string, string>
  ).toString();

  return (
    <Content pageTitle="Dashboard">
      <Dashboard
        name="series"
        onClickDashboardItem={(elem) =>
          router.push(
            `/reports/${elem._id}${queryString ? "?" + queryString : ""}`
          )
        }
      />
    </Content>
  );
}
